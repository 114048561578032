import React from "react";
import Bucket from "./components/Bucket";

function App() {
  return (
    <>
      <Bucket></Bucket>
    </>
  );
}

export default App;
